import React from 'react';
import Resume from '../assets/Michael_Mamich_Resume.pdf';
import Photo from '../assets/mikey_beach.jpg';

const Header = () => {
  return (
    <header className="header">
      <div className="tagline-section">
        <h1 className="tagline">Michael Mamich</h1>
        <h3 className="tagline tagline-muted">Building and designing websites with attention to detail</h3>
        <a href={Resume} target="_blank" rel="noopener noreferrer" className="remove-underline"><button className="button-md button-blue rm-link-style">My Resume</button></a>
      </div>
      <div className="header-img">
        <img src={Photo} alt="Michael Mamich" className="mikey" />
      </div>
    </header>
  );
}

export default Header;
