import React, {useState, useEffect} from 'react';
import { Fade as Hamburger } from 'hamburger-react';
import '../styles/style.scss';

const Navbar = () => {

  const [showLinks, setShowLinks] = useState(false);

  /*Adds Overflow to mobile menu*/
  useEffect(() => {
    if(showLinks) {
      document.body.className = 'overflow';
    }
    return () => { document.body.className = ''; }
  });

  return (
    <nav className="flex-container">
      <div className="flex-left">
        <p className="logo">MMM</p>
      </div>
      <div className="flex-right">
        <ul className="navbar-flex" id={showLinks ? "hidden" : "" }>
          <li className="navbar-item"><a href="#work">Work</a></li>
          <li className="navbar-item"><a href="#about">About</a></li>
          <li className="navbar-item"><a href="#footer">Contact</a></li>
        </ul>
        <div className="hamburger">
          <Hamburger
            toggled={showLinks}
            toggle={setShowLinks}
            size={25}
          />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
