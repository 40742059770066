import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faCodepen, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="footer-container" id="footer">
      <h3>Contact Me</h3>
      <a href="mailto:MMMamich@gmail.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faEnvelope} className="social-icon"></FontAwesomeIcon></a>
      <a href="https://github.com/mmmamich" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} className="social-icon"></FontAwesomeIcon></a>
      <a href="https://codepen.io/mmmamich" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faCodepen} className="social-icon"></FontAwesomeIcon></a>
      <a href="https://www.linkedin.com/in/michael-mamich/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} className="social-icon"></FontAwesomeIcon></a>
    </footer>
  );
}

export default Footer;
