import React, { useEffect } from 'react';
import '../styles/layout/_work.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import Aos from "aos";
import "aos/dist/aos.css";

const Work = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <div id="work">
      <h1 className="work-title">Projects</h1>
      <div className="grid-container">
        <article className="article-card" data-aos="fade-down">
          <h3>Jewel Game</h3>
          <p>Game in which jewels will be assigned random numbers and you will have to add the jewels to the total that is displayed on the screen! First project that compiled css and html skills with JavaScript skills.</p>
          <a href="https://github.com/MMMamich/Jewel-Game" target="_blank" rel="noopener noreferrer" className="social-icon-container">
            <FontAwesomeIcon icon={faCodeBranch} className="social-icon"></FontAwesomeIcon>
            Repo
          </a>
        </article>
        <article className="article-card" data-aos="fade-down">
          <h3>Platypus Music</h3>
          <p>A website that allows you to dynamically and efficiently chat with other Geo-Cannon users! Simply post a message to show your location and find other users that are around you to chat with them. Chatting locally made simple.</p>
          <a href="https://github.com/PlatypusMusic/PlatypusMusic" target="_blank" rel="noopener noreferrer" className="social-icon-container">
            <FontAwesomeIcon icon={faCodeBranch} className="social-icon"></FontAwesomeIcon>
            Repo
          </a>
        </article>
        <article className="article-card" data-aos="fade-down">
          <h3>Geo Cannon</h3>
          <p>Website allows you to dynamically and efficiently chat with other Geo-Cannon users! Simply post a message to show your location and find other users that are around you to chat with them. Chatting local made simple.</p>
          <a href="https://github.com/squirtle-crew/Geo-Cannon" target="_blank" rel="noopener noreferrer" className="social-icon-container">
            <FontAwesomeIcon icon={faCodeBranch} className="social-icon"></FontAwesomeIcon>
            Repo
          </a>
        </article>
        <article className="article-card" data-aos="fade-down">
          <h3>Mentor Meetup</h3>
          <p>This web app connects people who have a particular skill with someone who would like to learn more about it. This could be a professional skill such as coding or marketing and utilizes the MERN stack.</p>
          <a href="https://github.com/MentorMeetUp/MongooseMentorMeetup" target="_blank" rel="noopener noreferrer" className="social-icon-container">
            <FontAwesomeIcon icon={faCodeBranch} className="social-icon"></FontAwesomeIcon>
            Repo
          </a>
        </article>
      </div>
    </div>
  );
}

export default Work;
