import Layout from './components/Layout';
import './styles/style.css';

function App() {
  return (
    <Layout />
  );
}

export default App;
