import React, { useEffect } from 'react';
import Skills from './Skills';
import Hockey from '../assets/Hockey.jpg'
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <div className="about-container" id="about" data-aos="fade-down">
      <div className="about-flex">
        <div className="about-img">
          <img src={Hockey} alt="Hockey" />
        </div>
        <div className="about-me">
          <h3>About Me</h3>
          <p>Combining design and logic is what gets me excited. Whether it's my personal projects or open-source projects I just enjoy hacking away. I enjoy playing hockey, skateboarding, and jamming out on the keys/drums. If you'd like to contact me please <a href="mailto:MMMamich@gmail.com" rel="noopener noreferrer">email me</a>.</p>
          <Skills data-aos="fade-down" />
        </div>
      </div>
    </div>
  );
}

export default About;
