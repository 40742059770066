import React, { Component } from 'react';

import About from './About';
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';
import Work from './Work';

class Layout extends Component {
  state = {}

  render() {
    console.log('Hey whatcha doing back here!? 🤓');
    return (
      <main>
        <Navbar />
        <Header />
        <div className="container">
          <Work></Work>
          <About></About>
        </div>
        <Footer />
      </main>
    );
  }
}

export default Layout;
