import React from 'react';

const Skills = () => {
  return (
    <div>
      <h3>Skills</h3>
      <p>My expertise lies in the MERN stack with a focus on Modern JavaScript. I've worked with CMS's like WordPress and Joomla which have allowed me to use PHP and MySQL. I utilize Cloud Computing Services such as Digital Ocean and AWS (Lightsail/EC2/Amplify) to streamline website production. </p>
    </div>
  );
}

export default Skills;
